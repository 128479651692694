
import {defineComponent, PropType, reactive, ref, toRefs} from "vue";
import request from "@/utils/request";
import qs from "qs";
import {ElForm} from "element-plus";
import {useRouter} from "vue-router";
import store from "@/store";

export default defineComponent({
  props:{
    arr:{
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(){
    const myLoginFrom = ref<typeof ElForm>();
    const router = useRouter();
    let state = reactive({

      // 是否显示登陆页面
      // isShow: false,
      formData: {
        userName: "", // 用户名
        password: "", // 密码
      },
    });
    const rules = {
      userName: [
        { required: true, message: "请输入用户名", trigger: "blur" },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    };

    return{
      ...toRefs(state),
      rules,
      myLoginFrom,
      loginClick
    }

    function loginClick() {

      myLoginFrom.value?.validate((valid:boolean) => {
        if (valid) {
          request({
            url: "/admin/login",
            method: "GET",
            params: state.formData,
          }).then((res) => {
            const d = (res as Record<string, any>).content;

            store.admintoken = d.token;
            store.userType = d.userType;

            router.push('/');

          });
        }
      });
    }
  }
});

